html,
body,
#root {
  margin: 0px;
  padding: 0px;
  height: 100%;
}

body {
  background-color: #f4f4f6;
}

a {
  text-decoration: none;
}
